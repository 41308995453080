import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
// Hooks Querys & Context
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

import ModuleZone from '~moduleZone';
import Layout from '@components/layout';
import { PageMeta } from '@components/pageMeta';

const ConcertBanner = loadable(() =>
  import('../components/pageModules/modules/concertBanner'),
);

const IndexPage = ({ data: { page } }) => {
  const { pageModules, config, seo, homeBanner } = page || {};
  useEffect(() => {
    try {
      var klaviyo = klaviyo || [];
      var page = window.location.href;
      klaviyo.push([
        'track',
        'Viewed Page',
        { url: 'https://www.chambermusic.co.nz/' },
      ]);
    } catch (err) {
      console.log('Error occurred adding klaviyo script: ' + err);
    }
  }, []);
  return (
    <Layout config={config}>
      <PageMeta {...seo} />
      {homeBanner && <ConcertBanner {...homeBanner} />}
      {pageModules && <ModuleZone {...pageModules} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      seo {
        ...SEO
      }
      pageModules: modules {
        ...PageModules
      }
      config: pageConfig {
        reverseHeader
        unpinnedHeader
      }
      homeBanner {
        concerts {
          ... on SanityBannerSlide {
            _key
            _type
            excerpt
            featuredImage {
              ...ImageWithPreview
            }
            title: _rawTitle(resolveReferences: { maxDepth: 10 })
            slideColor
            pageButton {
              ...PageLink
            }
          }
          ... on SanityEventReference {
            _key
            _type
            slideColor
            concert {
              ...Event
            }
          }
        }
      }
    }
  }
`;
